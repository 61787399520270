@import '../styles/mq.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .title {
    margin: 0 0 1rem;
    font-size: 8vw;

    @include md {
      font-size: 4vw;
    }
  }

  .subtitle {
    margin: 0 0 3.5rem;
    font-size: 3vw;

    @include md {
      font-size: 1.5vw;
    }
  }

  .autocomplete {
    width: 100%;
  }

  .input {
    padding: 1rem 1.2rem;
    width: 100%;
    border: 5px solid;
    outline: none;
    line-height: 1.5;
    font-size: 1.3rem;
  }

  .list-box {
    position: absolute;
    margin: 0;
    padding: 0;
    max-height: 60%;
    border: 5px solid;
    border-top: none;
    list-style-type: none;
    text-align: left;
    overflow-y: auto;
  }

  .list-item {
    padding: 1rem 1.2rem;
    cursor: pointer;
    background: #fff;
  }

  .list-item-darken {
    color: #fff;
    background: #000;
  }

  .sub-text {
    margin-left: 0.5rem;
    color: #8c8c8c;
  }

  .logo {
    padding: 1rem 1.2rem;
    text-align: right;
  }
}
