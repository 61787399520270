.report {
  border: 5px solid;
  background: white;
  padding: 1rem 1.2rem;
  font-size: 1.3rem;
  line-height: 1.2;
  margin: 2rem 0;
  width: 100%;

  ul {
    list-style-type: none;

    li {
      margin-left: 2rem;
    }
  }

  > div {
    margin: 3vh auto;

    h3 {
      margin-bottom: 0.5vh;
      font-weight: bold;
    }
  }
}

.reportBlacklisted {
  display: block;
  text-align: center;
  text-transform: uppercase;
  color: red;
}

.reportHeader,
.reportRecommendation {
  font-size: 1.5rem;
  margin: 0 0 0.5em 0;
  text-align: center;
}

.reportRecommendation {
  font-size: 3rem;
}

.reportCoverage {
  color: green;
}

.reportNoCoverage {
  color: red;
}

.reportUnknown {
  color: orange;
}

.linkList {
  margin-top: 1vh;
  li {
    margin-left: 4rem;

    &::before {
      margin-right: 0.5rem;
      content: '⬈';
    }
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  p {
    display: block;
    margin: 2vh 2rem;
  }
}

.checkout {
  margin: 1rem 0;

  p {
    margin: 0.5rem 0 1rem 0;
  }

  select {
    margin: 0 0.5rem;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
    margin: 0 1rem;
  }
}
