.main {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 800px;
  min-height: 100vh;
  padding: 6rem 3rem;
}
